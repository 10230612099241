import * as React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cx from "classnames";

// Use for DYNAMIC images
// where image is passed from CMS
const Image = ({
  image,
  alt = "",
  withFrame = false,
  caption = null,
  quality = 50,
  className = "",
}) => {
  const imageSrc = getImage(image);

  return (
    <div className={cx(className && className)}>
      {imageSrc && (
        <GatsbyImage
          image={imageSrc}
          alt={alt}
          className={cx(
            withFrame &&
              "bg-black border-black border-[6px] rounded-[18px] lg:border-8",
          )}
          quality={quality}
        />
      )}
      {caption && (
        <figcaption className="font-inter text-sm text-center text-grey-dark pt-2">
          {caption}
        </figcaption>
      )}
    </div>
  );
};

export default Image;

Image.propTypes = {
  image: PropTypes.object, // gatsbyImage object
  alt: PropTypes.string,
  withFrame: PropTypes.bool,
  caption: PropTypes.string,
  quality: PropTypes.number,
  className: PropTypes.string,
};
