import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import Layout from "../components/common/Layout";
import Section from "../components/common/Section";

import ProjectCard from "../components/project/ProjectCard";

const Projects = ({ data }) => {
  if (!data) return null;

  const { prismicProjects, allPrismicProject } = data;

  const content = prismicProjects?.data || null;
  const allProjects = allPrismicProject?.nodes || null;

  return (
    <Layout>
      {content && (
        <Section title={content.heading.text} intro={content.intro_text.text}>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-16 lg:gap-20 max-w-7xl mx-auto pt-10">
            {allProjects &&
              allProjects.map((proj) => {
                return (
                  <Link
                    to={`/projects/${proj.data.slug}`}
                    key={`project-${proj.data.short_name.text}`}
                  >
                    <ProjectCard project={proj.data} />
                  </Link>
                );
              })}
          </div>
        </Section>
      )}
    </Layout>
  );
};

Projects.propTypes = {
  data: PropTypes.shape({
    prismicProjects: PropTypes.shape({
      data: PropTypes.shape({
        heading: PropTypes.shape({ text: PropTypes.string }),
        intro_text: PropTypes.shape({ text: PropTypes.string }),
      }),
    }),

    allPrismicProject: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          data: PropTypes.shape({
            short_name: PropTypes.shape({ text: PropTypes.string }),
            short_description: PropTypes.shape({ text: PropTypes.string }),
            thumbnail: PropTypes.shape({ url: PropTypes.string }),
          }),
        }),
      ),
    }),
  }),
};

export default Projects;
