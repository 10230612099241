import React from "react";
import PropTypes from "prop-types";

import Image from "../common/Image";
import Paragraph from "../common/Paragraph";
import Tag from "../common/Tag";
import Level from "../common/Level";
import Flag from "../common/Flag";

function ProjectCard({ project }) {
  const comingSoon = project.status === "coming_soon_display_only";
  return (
    <section
      key={`project-${project.short_name.text}`}
      className="project mb-8 md:mb-12 group/card"
    >
      <div className="relative">
        {comingSoon && (
          <Flag className="absolute top-10 right-0 z-10">Coming soon</Flag>
        )}
        <Image
          image={project.thumbnail}
          alt={project.short_name.text}
          withFrame
        />
      </div>
      <div className="flex justify-between border-b border-black mb-4 md:mb-6 pt-4 md:pt-8 pb-2">
        <h3 className="font-dm-sans font-medium antialiased text-2xl sm:text-3xl lg:text-3xl uppercase pr-4 leading-none">
          {project.short_name.text}
        </h3>
        <Level
          className=""
          lowerLevel={project.project_level_lower_end}
          upperLevel={project.project_level_upper_end}
          showExplanation
          topTight
        />
      </div>
      <div className="h-28 relative">
        <Paragraph className="mb-6">{project.short_description.text}</Paragraph>
        <div className="hidden lg:flex flex-wrap gap-2 sm:gap-3 lg:gap-4 opacity-0 group-hover/card:opacity-100 transition-opacity">
          {project.tags.map((tag, i) => {
            return (
              <Tag
                key={i}
                name={tag.tag_name}
                isRequired={tag.language_is_required}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default ProjectCard;

ProjectCard.propTypes = {
  project: PropTypes.shape({
    short_name: PropTypes.shape({ text: PropTypes.string }),
    short_description: PropTypes.shape({ text: PropTypes.string }),
    thumbnail: PropTypes.shape({ url: PropTypes.string }),
    project_level_lower_end: PropTypes.string,
    project_level_upper_end: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        isRequired: PropTypes.bool,
      }),
    ),
  }),
};
