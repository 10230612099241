import * as React from "react";
import { graphql } from "gatsby";

import ProjectsPageTemplate from "../templates/projectsPage.js";
import CustomHead from "../components/common/Head";

export const query = graphql`
  {
    prismicProjects {
      data {
        page_title
        page_description
        heading {
          text
        }
        intro_text {
          text
        }
      }
    }
    allPrismicProject(
      filter: {
        data: {
          status: { in: ["live", "pre_sale", "coming_soon_display_only"] }
        }
      }
      sort: { fields: data___public_release_date }
    ) {
      nodes {
        data {
          slug
          short_name {
            text
          }
          short_description {
            text
          }
          thumbnail {
            gatsbyImageData(
              placeholderImgixParams: { blur: 10 }
              placeholder: BLURRED
            )
            alt
          }
          status
          project_type
          project_level_lower_end
          project_level_upper_end
          tags {
            tag_name
            language_is_required
          }
        }
      }
    }
  }
`;

const ProjectsPageWithData = ({ data, location }) => (
  <ProjectsPageTemplate data={data} />
);

export const Head = ({ data }) => {
  const pageMeta = {
    title: data.prismicProjects.data.page_title,
    description: data.prismicProjects.data.page_description,
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default ProjectsPageWithData;
